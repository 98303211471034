<template>
  <div class="users-add-container block">
    <h2 class="users-add-title block-wrapper header">Add new user</h2>
    <form @submit.prevent="submit" class="users-add-form block-wrapper">
      <div class="users-add-inputs">
        <Input
          label="Login"
          placeholder="Enter login"
          name="login"
          v-model.trim="$v.login.$model"
          :error="errorLogin"
        />
        <Input
          label="Password"
          placeholder="Enter password"
          type="password"
          name="password"
          v-model.trim="$v.password.$model"
          :error="$v.password.required && $v.password.$error ? 'Required' : null"
        />
        <Dropdown v-if="role.value === 'USER'" :list="listLang" :active="lang" @selected="setLang"/>
        <Dropdown :list="roles" :disabled="user.role === 'SUBADMIN'" :active="role" @selected="setRole"/>
      </div>
      <button class="btn blue" :disabled="$v.$invalid" type="submit">Save new user</button>
    </form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import {eventBus} from '@/main';
import languages from '@/utils/languages';

export default {
  name: "UsersAddUser",
  components: {
    Input: () => import('@/components/common/AInput'),
    Dropdown: () => import('@/components/common/ADropdown'),
  },
  mixins: [validationMixin],
  validations: {
    login: {
      required
    },
    password: {
      required
    },
  },
  data() {
    return {
      login: '',
      password: '',
      pending: false,
      lang: languages[0],
      role: {},
      listLang: languages
    }
  },
  computed: {
    ...mapGetters({userRoles: 'roles', user: 'user'}),
    errorLogin() {
      if (this.$v.login.$error && this.$v.login.required) {
          return 'required'
      }
      return null;
    },
    roles() {
      const hideRoles = ['SUPERADMIN', this.user.role !== 'SUPERADMIN' && 'ADMIN']
      return this.userRoles.filter(r => !hideRoles.includes(r.value))
    }
  },
  methods: {
    ...mapActions(['createUser']),
    async submit() {
      await this.createUser({
        password: this.password,
        email: this.login,
        user_role: this.role.value,
        language: this.lang.value
      }).then(() => {
        this.password = '';
        this.login = '';
        this.$notify({
          title: 'Create user',
          text: 'Success creating user!',
          type: 'success'
        });
        eventBus.$emit('onLoadUsers');
      }).catch((error) => {
        console.error('Error create user:', error);
        this.$notify({
          title: 'Create user',
          text: error.response.data.message,
          type: 'error'
        });
      });
    },
    setLang(lang) {
      this.lang = lang;
    },
    setRole(role) {
      this.role = role;
    }
  },
  created() {
    if(this.user.role === 'SUBADMIN') {
      this.role = this.roles.find(r => r.value === 'USER');
    } else {
      this.role = this.roles[0];
    }
  },
}
</script>

<style scoped>

</style>
